<template>
  <div class="app-wrapper">
    <sidebar class="sidebar-container" />
    <div class="main-right-container">
      <!-- <div class="menu-left-padding"></div> -->
      <div :class="{ hasTagsView: needTagsView }" class="main-container">
        <div :class="{ 'fixed-header': fixedHeader }">
          <navbar />
          <!-- <tags-view v-if="needTagsView" /> -->
        </div>
        <app-main />
        <!-- <right-panel v-if="showSettings">
          <settings />
        </right-panel> -->
      </div>
    </div>
  </div>
</template>

<script>
import { AppMain, Navbar, Sidebar } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    Sidebar
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader
    })
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
  // margin:0 auto;
  overflow-x: hidden;
  // display: flex;
  // justify-content: space-between;
  .main-right-container {
    width: 1440px;
    overflow-x: hidden;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
  .menu-left-padding {
    width: 192px;
  }
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 900;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
</style>
