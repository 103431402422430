/*
 * @Descripttion:
 * @version:
 * @Author: zg
 * @Date: 2021-06-01 17:16:01
 * @LastEditors:
 * @LastEditTime: 2021-06-01 17:17:06
 */
import store from '../store'

export function hasPermission(permission) {
  let myPermissions = store.getters.permissions
  return myPermissions.indexOf(permission) > -1
}
