import { Table, Pagination } from 'element-ui'
import Vue from 'vue'

export default {
  name: 'ExTable',
  mixins: [Table],
  props: {
    showPagination: {
      type: Boolean,
      default: true
    },
    pager_layout: {
      type: String,
      default: 'total,sizes,prev, pager, next, jumper'
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default: () => [10, 15, 30, 50]
    },
    getData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      pagination: null
    }
  },
  methods: {
    setCurrentPage(page) {
      this.pagination.currentPage = page
      this.fetchData()
    },
    setPageSize(size) {
      this.pagination.pageSize = size
      this.pagination.currentPage = 1
      this.fetchData()
    },
    fetchData() {
      if (this.showPagination) {
        this.getData(this.pagination, {
          page: this.pagination.currentPage,
          rows: this.pagination.pageSize
        })
      } else {
        this.getData()
      }
    },
    mountPagination() {
      const container = document.createElement('div')
      const parent = this.$el.parentNode
      if (parent.lastChild == this.$el) {
        parent.appendChild(container)
      } else {
        parent.insertBefore(container, this.$el.nextSibling)
      }
      const Pager = Vue.extend(Pagination)
      this.pagination = new Pager({
        components: { Pagination },
        propsData: {
          pageSize: this.pageSize,
          pageSizes: this.pageSizes,
          layout: this.pager_layout,
          background: true,
          total: 0,
          currentPage: 1,
          hideOnSinglePage: false
        }
      })
      this.pagination.$on('current-change', this.setCurrentPage)
      this.pagination.$on('size-change', this.setPageSize)
      this.pagination.$mount(container)
    }
  },
  mounted() {
    if (this.showPagination) {
      this.mountPagination()
    }
  },
  beforeDestroy() {
    // this.pagination.$off('current-change', this.setCurrentPage);
    // this.pagination.$off('size-change', this.setPageSize);
  }
}
