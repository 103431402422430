<!--
 * @Descripttion:
 * @version:
 * @Author: zg
 * @Date: 2021-06-10 10:15:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-06-28 10:32:33
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      websock: null
    }
  },
  created() {
    // this.initWebSocket()
    // 屏蔽右键菜单
  },
  destroyed() {
    // this.websock.close() // 离开路由之后断开websocket连接
  },

  methods: {
    initWebSocket() {
      // 初始化weosocket
      const wsuri = process.env.VUE_APP_SOCKET_URL
      // const wsuri = 'ws://dsw.shuyitech.cn/house/scoket/warning'
      this.websock = new WebSocket(wsuri)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      console.log('websocket已建立链接')
    },
    websocketonerror() {
      // 连接建立失败重连
      this.initWebSocket()
    },
    websocketonmessage(res) {
      // 数据接收
      const { data } = res
      // console("===数据推送==="+res.data)
      this.$store.dispatch('user/setWarningCount', data)
    },
    websocketsend(Data) {
      // 数据发送
      this.websock.send(Data)
    },
    websocketclose(e) {
      // 关闭
      console.log('断开连接', e)
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val.fullPath) {
          this.$store.dispatch('user/changePath', val.fullPath)
        }
      },
      // 深度观察监听
      deep: true
    }
  }
}
</script>
<style>
@import '//at.alicdn.com/t/font_2582315_tav25r7h58.css';
</style>
