<template>
  <div class="menu-container">
    <div>
      <template>
        <span>
          <i :class="['iconfont', icon]"></i>
        </span>
      </template>
      <template>
        <span class="menu-title" slot="title">{{ title }}</span>
      </template>
    </div>
    <div class="bg-color" v-if="title == '预警监控' && showFlag && warningCount > 0">
      <span v-if="warningCount > 99">+99</span>
      <span v-else>{{ warningCount }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: [String, Array]
    },
    path: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    return {
      showFlag: true,
      timer: null
    }
  },
  methods: {},
  mounted() {
    // this.timer = setInterval(() => {
    //   this.showFlag = !this.showFlag;
    // }, 1000);
  },
  watch: {},
  computed: {
    ...mapGetters(['warningCount', 'currentPath'])
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>
<style lang="scss" scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.menu-container {
  display: flex;
  .iconfont {
    margin-right: 6px;
  }
  .menu-item {
    color: $--color-primary !important;
  }
}
.bg-color {
  margin: 10px 0 0 5px;
  transition: 1s;
  -moz-transition: 1s;
  -webkit-transition: 1s;
  -o-transition: 1s;
  text-align: center;
  display: block;
  color: #fff;
  background: #f00;
  border-radius: 50%;
  width: 35px;
  height: 25px;
  line-height: 25px;
  /* top: 0px; */
  /* right: -0.2em; */
  /* position: absolute; */
}
</style>
