import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'

// create an axios instance
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    let { params } = config
    if (params) {
      const keys = Object.keys(params)
      for (const key of keys) {
        const value = params[key]
        if (Array.isArray(value)) {
          if (value.length == 0) {
            delete params[key]
          } else {
            params[key] = value + ''
          }
        } else {
          switch (value) {
            case undefined:
            case null:
            case '':
              delete params[key]
              break
          }
        }
      }
    }
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['accessToken'] = getToken()
    }
    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
request.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.config?.responseType == 'blob') {
      return response.data //响应格式为流时单独处理
    }
    if (res.returnCode != 17000) {
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.returnCode === '11001' || res.returnCode === '11002') {
        // to re-login
        Message({
          message: '登录已失效！',
          type: 'error',
          duration: 2 * 1000
        })
        store.dispatch('user/resetToken').then(() => {
          location.reload()
        })
        // MessageBox.confirm('您的登录已经过期或者无效, 您可以留在当前页或重新登录', '提示', {
        //   confirmButtonText: '重新登录',
        //   cancelButtonText: '取消',
        //   type: 'warning'
        // }).then(() => {
        //   store.dispatch('user/resetToken').then(() => {
        //     location.reload()
        //   })
        // })
      } else if (res.returnCode) {
        let doms = document.getElementsByClassName('el-message')[0]
        if (doms == undefined) {
          if (response.config?.showError != false) {
            Message({
              message: res.returnDesc || 'Error',
              type: 'error',
              duration: 5 * 1000
            })
          }
        }
      }
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res)
    }
  },
  (error) => {
    let doms = document.getElementsByClassName('el-message')[0]
    if (doms == undefined) {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default request
