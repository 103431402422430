<template>
  <div class="navbar-top">
    <div class="navbar">
      <div style="float: left; width: 450px"><logo /></div>
      <div class="right-menu">
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            {{ nickname }}
            <!-- 用户名 -->
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item divided @click.native="logout">
              <span style="display: block">退出</span>
            </el-dropdown-item>
            <!-- <span style="display:block;">退出</span> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from './Sidebar/Logo'
import { mapGetters } from 'vuex'
export default {
  components: {
    Logo
  },
  data() {
    return {
      path: process.env.BASE_URL,
      title: '数一科技数字监管仓'
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device', 'name', 'nickname'])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar-top {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;

  height: 64px;
  overflow: hidden;
  // position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  border-bottom: 1px solid #eeeeee;
  z-index: 1000;
}
.navbar {
  position: absolute;
  top: 0;
  right: calc(50% - 720px);
  width: 100%;
  max-width: 1440px;

  height: 64px;
  overflow: hidden;
  // position: relative;
  background: #fff;
  .right-menu {
    float: right;
    height: 100%;
    line-height: 64px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 30px;
      .avatar-wrapper {
        // margin-top: 5px;
        position: relative;
        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }
        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
