<template>
  <div>
    <svg-icon icon-class="empty" />
    <span>暂无数据</span>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss"></style>
