<template>
  <div class="tipinfo">
    <i class="el-icon-info" /> 已选择 <span>{{ value.length }}</span> 条记录
    <a @click="clear">&emsp;清空</a>
  </div>
</template>
<script>
export default {
  name: 'TipInfo',
  props: {
    value: {
      type: [Array, Object],
      default: function () {
        return []
      }
    }
  },
  methods: {
    clear() {
      this.$emit('clear')
    }
  }
}
</script>
<style lang="scss">
.tipinfo {
  margin: 5px 10px;
  line-height: 38px;
  height: 38px;
  border: 1px solid $--color-primary;
  border-radius: 3px;
  color: #333;
  font-size: 14px;
  padding: 0 14px;
  text-align: left;
  i {
    color: $--color-primary;
    margin-right: 10px;
  }
  span {
    color: $--color-primary;
    margin: 0 5px;
    font-weight: 800;
  }
  a {
    color: $--color-primary;
    margin: 0 10px;
    cursor: pointer;
  }
}
</style>
