import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    scope: Object,
    render: Function
  },
  render() {
    return this.render(this.scope)
  }
})
