<template>
  <el-collapse-transition>
    <div ref="searchBox" v-if="show" :class="['search-box', collaspe ? 'collaspe' : '']">
      <slot></slot>
      <div class="footer">
        <slot name="footer"></slot>
        <div class="expand" v-if="display">
          <span v-if="collaspe == true" @click="toggle(false)">展开<i class="el-icon-arrow-down"></i></span>
          <span v-else @click="toggle(true)">折叠<i class="el-icon-arrow-up"></i></span>
        </div>
      </div>
    </div>
  </el-collapse-transition>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      display: false,
      collaspe: true,
      show: true
    }
  },
  props: {
    limit: {
      type: Number,
      default: 3 //以每行3个计算
    }
  },
  mounted() {
    let length = this.$slots.default?.length || 0
    if (length / this.limit > 1) {
      this.display = true
    }
  },
  methods: {
    toggle(value) {
      this.collaspe = value
    },
    setCollapse() {
      this.show = !this.show
    }
  }
}
</script>
<style lang="scss">
.search-box {
  --flex-basic: 20%;
  padding: 15px 10px 0px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  // transition: 0.3s height ease-in-out;
  &.collaspe {
    & > *:not(.footer):nth-child(n + 4) {
      display: none;
    }
  }
  & > * {
    margin-bottom: 10px;
  }
  & > *:not(.footer) {
    margin-right: 10px !important;
    flex-basis: var(--flex-basic, 20%);
    .el-select > .el-input {
      width: 100%;
    }
  }
  .footer {
    margin-left: auto;
    margin-right: 20px;
    align-self: center;
    .expand {
      color: $--color-primary;
      display: inline-block;
      cursor: pointer;
      margin-left: 10px;
      user-select: none;
      i {
        margin-left: 3px;
      }
    }
  }
}
</style>
