import request from '@/utils/request'
export function login(data) {
  return request({
    //TODO: url: '/house/user/login',
    url: '/house/shiro/auth',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/house/shiro/getInfo',
    method: 'get',
    params: { token }
  })
}
