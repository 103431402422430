<template>
  <el-date-picker v-model="selectValue" :type="type" range-separator="-" :value-format="valueFormat" :format="format" v-bind="$attrs" @change="change"></el-date-picker>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      selectValue: '',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    text: {
      type: String
    },
    value: {
      type: [String, Array]
    },
    type: {
      type: String,
      default: 'daterange'
    }
  },
  methods: {
    change(val) {
      this.selectValue = val
      console.log(val)
      this.$emit('change', val || [])
    }
  },
  watch: {
    type: {
      handler: function (newVal) {
        if (newVal == 'datetimerange') {
          this.format = 'MM-dd HH:mm:ss'
          this.valueFormat = 'yyyy-MM-dd HH:mm:ss'
        }
      },
      immediate: true
    },
    value: {
      handler: function (newVal, oldVal) {
        this.selectValue = newVal
      },
      immediate: true
    }
  }
}
</script>
<style lang="scss" scoped></style>
