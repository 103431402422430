import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken, getUserName, setUserName, removeUserName } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
  token: getToken(),
  name: getUserName(),
  avatar: '',
  introduction: '',
  roles: [],
  userId: '',
  warningCount: 0,
  currentPath: '',
  permissions: [],
  nickname: ''
}

const mutations = {
  SET_USER: (state, userId) => {
    state.userId = userId
  },
  RESET_USER: (state) => {
    state.userId = ''
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_NICKNAME: (state, nickname) => {
    state.nickname = nickname
  },
  SET_COUNT: (state, count) => {
    state.warningCount = count
  },
  SET_PATH: (state, count) => {
    state.currentPath = count
  },
  SET_PERMISSIONS: (state, permissionList) => {
    state.permissions = permissionList
  }
}

const actions = {
  setPermissions: ({ commit }, permissionList) => {
    commit('SET_PERMISSIONS', permissionList)
  },
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          if (response.returnCode == '17000') {
            commit('SET_TOKEN', data)
            commit('SET_NAME', username)
            setToken(data)
            setUserName(username)
          }
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((response) => {
          const { data } = response
          if (!data) {
            reject('Verification failed, please Login again.')
          }
          commit('SET_USER', data.userPermission.userId)
          commit('SET_ROLES', data.userPermission.menuList)
          commit('SET_PERMISSIONS', data.userPermission.permissionList)
          commit('SET_NICKNAME', data.userPermission.nickname)

          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      removeUserName()
      resetRouter()
      //dispatch('tagsView/delAllViews', null, { root: true })
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
  setWarningCount({ commit }, count) {
    commit('SET_COUNT', count)
  },
  changePath({ commit }, path) {
    commit('SET_PATH', path)
  },
  // dynamically modify permissions
  async changeRoles({ commit, dispatch }, role) {
    const token = role + '-token'

    commit('SET_TOKEN', token)
    setToken(token)

    const { roles } = await dispatch('getInfo')

    resetRouter()

    // generate accessible routes map based on roles
    const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
    // dynamically add accessible routes
    router.addRoutes(accessRoutes)

    // reset visited views and cached views
    //dispatch('tagsView/delAllViews', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
