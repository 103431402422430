<template>
  <div class="toolbox">
    <slot></slot>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {}
  },
  methods: {}
}
</script>
<style lang="scss">
.toolbox {
  display: flex;
  margin: 0px 10px 10px;
  .footer {
    margin-left: auto;
    margin-right: 40px;
    align-self: center;
  }
}
</style>
