<template>
  <el-dialog :visible.sync="dialogVisible" class="dialog" width="400px" center show-close @close="dialogVisible = false" append-to-body>
    <template #title>
      <span>{{ title }}</span>
    </template>
    <slot name="content" />
    <template #footer>
      <el-button type="primary" @click="handlerNext" :loading="loading">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import dialog from '@/mixin/dialog'
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  mixins: [dialog]
}
</script>
<style lang="scss">
.el-dialog__wrapper.dialog {
  .el-dialog {
    border-radius: 3px;
    .el-dialog__header {
      padding: 25px 25px 10px;
    }

    .el-dialog__body {
      padding: 15px 50px;
      text-align: center;
      color: #1e1e1e;
      font-size: 14px;
    }
    .el-dialog__footer {
      text-align: center;
      padding: 20px 20px;
    }
  }
}
</style>
