export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: null
    }
  },
  watch: {
    dialogVisible: function (newV) {
      this.$emit('update:visible', newV)
    },
    visible: function (newV) {
      this.dialogVisible = newV
    }
  },
  methods: {
    cancel() {
      this.dialogVisible = false
      this.$emit('cancel')
    },
    handlerNext() {
      this.$emit('next')
    }
  }
}
