<template>
  <div class="sidebar-logo-container">
    <div class="img-container">
      <transition name="sidebarLogoFade">
        <router-link key="expand" class="sidebar-logo-link" to="/">
          <div class="sidebar-logo">
            <img v-if="logo" :src="logo" class="icon" />
          </div>
        </router-link>
      </transition>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/gb-logo.png'
export default {
  name: 'SidebarLogo',
  props: {},
  data() {
    return {
      title: '数一科技数字监管仓',
      logo: logo
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  // background: #2b2f3a;
  text-align: left;
  overflow: hidden;
  .svg-icon {
    width: 230px;
    // vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
  .icon {
    width: 206px;
    height: 28px;
    vertical-align: middle;
    overflow: hidden;
  }
  .img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 64px;
    line-height: 64px;
    text-align: left;
    margin-left: 16px;
  }
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      // width: 60px;
      // height: 22px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }
}
</style>
