import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import Layout from '@/layout'
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    meta: { icon: 'icon-hy-shouye' },
    children: [
      {
        path: 'home',
        component: () => import('@/views/dashboard/index'),
        name: '首页',
        meta: { title: '首页', affix: true }
      }
    ]
  }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
  // 仓库展示
  {
    path: '/show',
    component: Layout,
    meta: {
      title: '货场展示',
      icon: 'icon-hy-huochangzhanshi',
      roles: ['houseShow']
    },
    children: [
      {
        path: 'http://wmstwins.shuyitech.cn:8086/',
        // component: () => import('@/views/houseMonitor/index'),
        roles: ['admin'],
        meta: { title: '仓库展示', noCache: true }
      }
    ]
  },
  {
    path: '/price',
    component: Layout,

    meta: {
      title: '价格盯市',
      icon: 'icon-hy-jiagedingshi',
      roles: 'price'
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/priceList/index'),
        meta: { title: '价格盯市', noCache: true }
      }
    ]
  },
  {
    path: '/goods',
    component: Layout,
    meta: {
      title: '仓库监控',
      icon: 'icon-hy-cangkujiankong',
      roles: ['houseMonitor']
    },
    children: [
      {
        path: 'monitor',
        component: () => import('@/views/goodsMonitor/index'),
        roles: ['admin'],
        meta: { title: '仓库监控', noCache: true }
      },
      {
        path: 'detail',
        component: () => import('@/views/goodsDetail/index'),
        roles: ['admin'],
        hidden: true,
        meta: { title: '货物明细', noCache: true }
      }
    ]
  },
  {
    path: '/impawn',
    component: Layout,

    meta: {
      title: '货物质押',
      roles: ['goodsPledge']
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/impawn/index'),
        meta: { title: '货物质押', icon: 'icon-hy-huowuzhiya', noCache: true }
      }
    ]
  },
  {
    path: '/decompression',
    component: Layout,
    meta: {
      roles: ['goodsSign']
    },

    children: [
      {
        path: 'index',
        component: () => import('@/views/decompression/index'),
        meta: { title: '货物解押', icon: 'icon-hy-huowujieya', noCache: true },
        roles: ['admin']
      }
    ]
  },
  {
    path: '/warningSet',
    component: Layout,

    children: [
      {
        path: 'index',
        component: () => import('@/views/warningSet/index'),

        meta: {
          roles: ['warning'],
          title: '预警监控',
          icon: 'icon-hy-jiankong',
          noCache: true
        }
      }
    ]
  },
  {
    path: '/financinglimit',
    component: Layout,
    meta: {
      title: '授信管理',
      roles: 'financ'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/financingLimit/index'),
        roles: ['admin'],
        meta: { title: '授信管理', icon: 'icon-hy-rongziedu', noCache: true }
      }
    ]
  },
  {
    path: '/deviceManage',
    component: Layout,
    meta: {
      title: '设备管理',
      roles: 'financ',
      icon: 'icon-hy-device'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/deviceManage/index'),
        roles: ['admin'],
        meta: { title: '智能门禁', noCache: true, roles: ['access'] }
      },
      {
        path: 'caculate',
        component: () => import('@/views/deviceManage/caculate'),
        roles: ['admin'],
        meta: { title: '智能计量', noCache: true, roles: ['access'] }
      },
      {
        path: 'camera',
        component: () => import('@/views/deviceManage/camera'),
        roles: ['admin'],
        meta: { title: '视频摄像', noCache: true }
      },
      // {
      //   path: 'crane',
      //   component: () => import('@/views/deviceManage/crane'),
      //   roles: ['admin'],
      //   meta: { title: '行车设备', noCache: true }
      // },
      // {
      //   path: 'iot',
      //   component: () => import('@/views/deviceManage/iot'),
      //   roles: ['admin'],
      //   meta: { title: '物联网设备', noCache: true }
      // },
      {
        path: 'device',
        component: () => import('@/views/deviceManage/device'),
        roles: ['admin'],
        meta: { title: '设备', noCache: true }
      }
    ]
  },
  {
    path: '/warnAdmin',
    component: Layout,
    meta: {
      title: '风险预警',
      roles: 'warn'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/warnAdmin/index'),
        roles: ['admin'],
        meta: { title: '风险预警', icon: 'icon-hy-yujingxinxichaxun', noCache: true }
      }
    ]
  },
  {
    path: '/createbusiness',
    component: Layout,
    meta: {
      title: '创建好押',
      roles: ['pledge'],
      icon: 'icon-hy-chuangjianhaoya',
      noCache: true
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/createBusiness/index'),
        // name: 'system',
        meta: { title: '创建好押', noCache: true }
      }
    ]
  },
  {
    path: '/managePledge',
    component: Layout,
    meta: {
      title: '好押业务管理',
      roles: 'pledge'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/managePledge/index'),
        meta: { title: '好押业务', icon: 'icon-hy-chaxunhaoyayewu', noCache: true }
      },
      {
        path: 'detail/:id?',
        component: () => import('@/views/managePledge/detail'),
        hidden: true,
        meta: { title: '好押业务', icon: 'icon-hy-chaxunhaoyayewu', noCache: true }
      }
    ]
  },
  {
    path: '/carManage',
    component: Layout,
    meta: {
      title: '车辆管理',
      roles: ['whitelist', 'car'],
      icon: 'icon-hy-device'
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/carManage/index'),

        meta: { title: '车辆信息', noCache: true, roles: ['whitelist'] }
      },
      {
        path: 'parkingSpace',
        component: () => import('@/views/carManage/parkingSpace'),

        meta: { title: '车位管理', noCache: true, roles: ['car'] }
      }
    ]
  },
  {
    path: '/systemManage',
    component: Layout,
    meta: {
      title: '系统管理',
      roles: ['system'],
      icon: 'icon-hy-xitongshezhi',
      noCache: true
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/systemManage/index'),
        // name: 'system',
        meta: { title: '仓库', noCache: true }
      },
      {
        path: 'yard',
        component: () => import('@/views/systemManage/yard'),
        meta: { title: '库区', noCache: true }
      }
    ]
  },
  {
    path: '/auth',
    component: Layout,
    meta: {
      title: '权限管理',
      roles: ['shiro'],
      icon: 'icon-hy-quanxianguanli',
      noCache: true
    },
    children: [
      {
        path: 'index',
        component: () => import('@/views/user/index'),
        name: 'role',
        meta: { title: '权限管理', noCache: true }
      }
    ]
  },
  {
    path: '/statistics',
    component: Layout,
    meta: {
      title: '数据统计',
      icon: 'icon-hy-statistic',
      noCache: true
    },
    children: [
      {
        path: 'car',
        component: () => import('@/views/statistics/car/index'),
        name: 'carStatistics',
        meta: { title: '出入库车辆统计', noCache: true, roles: ['record'] }
      },
      {
        path: 'warehouse',
        component: () => import('@/views/statistics/warehouse/index'),
        name: 'warehouseStatistics',
        meta: { title: '仓库计量统计', noCache: true, roles: ['meter'] }
      },
      {
        path: 'list1',
        component: () => import('@/views/statistics/report/list1'),
        name: 'list1',
        meta: { title: '库存表', noCache: true }
      },
      {
        path: 'list2',
        component: () => import('@/views/statistics/report/list2'),
        name: 'list2',
        meta: { title: '库存差异报表', noCache: true }
      },
      {
        path: 'list3',
        component: () => import('@/views/statistics/report/list3'),
        name: 'list3',
        meta: { title: '货物出库报表', noCache: true }
      },
      {
        path: 'list4',
        component: () => import('@/views/statistics/report/list4'),
        name: 'list4',
        meta: { title: '出库差异报表', noCache: true }
      },
      {
        path: 'list5',
        component: () => import('@/views/statistics/report/list5'),
        name: 'list5',
        meta: { title: '货物翻堆报表', noCache: true }
      },
      {
        path: 'list6',
        component: () => import('@/views/statistics/report/list6'),
        name: 'list6',
        meta: { title: '翻堆差异报表', noCache: true }
      }
    ]
  },
  {
    path: '/hw',
    component: () => import('@/views/systemManage/hw'),
    meta: {
      title: '货位'
    },
    hidden: true
  },
  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
