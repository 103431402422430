import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import 'normalize.css/normalize.css'
import './icons'
import './permission'
import * as filters from './filters'
import { hasPermission } from './filters/hasPermission'
import './plugins/ElementUI'
import '@/style/index.scss'
import '@/components/'

//import './sentry'

import moment from 'dayjs'

Vue.prototype.hasPerm = hasPermission
Vue.prototype.$moment = moment

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App)
})
